<template>
  <div class="contain" >
    <div class="banner">
      <div class="prompt">
        <div v-if="h != 0">
          {{ $t('collectLikes.prompt.one') }}
          <span id="prompt">{{h}}</span>
          {{ $t('collectLikes.prompt.two') }}
        </div>
        <div v-if="h == 0">
          {{ $t('collectLikes.prompt.timeout') }}
        </div>
        <img src="@/assets/image/icon_prompt.png" class="prompt_icon" />
      </div>
      <img src="@/assets/image/icon_banner.png" class="banner_icon" />
      <div class="coe">x {{ collect }}</div>
    </div>

    <img src="@/assets/image/ic_logo_right.png" class="ic_right_1" />
    <div class="content">
      <div>
        {{ $t('collectLikes.content.one') }}
      </div>
      <div class="content_two">
        {{ $t('collectLikes.content.two') }}
      </div>
    </div>

    <img src="@/assets/image/ic_left_qz.png" class="ic_left_1"  alt=""/>
    <div class="main">
      <div class="share_item" v-for="(item, index) in 9" :key="index">
        <img
            v-if="activeArr.includes(index.toString())"
            :src="itemSrcActive"
            class="itemIcon itemIcon_active"
        />
        <img v-else :src="itemSrc" class="itemIcon" @click="showDialog(index)"/>
      </div>
    </div>
    <img src="@/assets/image/ic_logo_right.png" class="ic_right_2" />
    <img src="@/assets/image/ic_logo_right.png" class="ic_right_3" />
    <div class="btn" @click="confirm">
      <img src="@/assets/image/money.png" class="money"  alt=""/>
      {{ $t('collectLikes.btn') }}
    </div>
    <img src="@/assets/image/ic_logo_right.png" class="ic_right_4" />
    <!-- 弹窗 -->
    <van-dialog
        v-model:show="show"
        style="width: 22rem; background: transparent;"
        :showConfirmButton="false"
        :overlay="false"
    >
      <div class="dialog">
        <img
            src="@/assets/image/icon_close.png"
            class="dialog_close"
            @click="dismissDialog"
        />
        <div class="dialog_content">
          <div v-if="h != 0">
            {{ $t('collectLikes.dialogContent.one') }}
          </div>
          <div v-if="h == 0">
            {{ $t('collectLikes.dialogContent.timeout') }}
          </div>
          <div>
            {{ $t('collectLikes.dialogContent.two') }}
          </div>
        </div>
        <div class="dialog_btns">
          <div class="confirm" @click="confirm">
            {{ $t('collectLikes.dialogBtns.confirm') }}
          </div>
        </div>
      </div>
    </van-dialog>


  </div>
</template>

<script>
import itemSrc from '@/assets/image/icon_item.png'
import itemSrcActive from '@/assets/image/icon_item_active.png'
import { v4 as uuidv4 } from 'uuid';

import { postLikeInfo, postLike } from '@/api/jump/collect'

export default {
  name: 'CollectLikes',
  data () {
    return {
      wHeight: '',
      show: false,
      itemSrc,
      itemSrcActive,
      count: 0,
      collect: 0,
      expire: null,
      h: 0,
      uuid: '275a2e5b-fa09-45ca-9b6a-9700fa6509b4',
      activeArr: []
    }
  },
  mounted () {
    // var clientHeight = 0
    // if (document.body.clientHeight && document.documentElement.clientHeight) {
    //   clientHeight =
    //     document.body.clientHeight < document.documentElement.clientHeight
    //       ? document.body.clientHeight
    //       : document.documentElement.clientHeight
    // } else {
    //   clientHeight =
    //     document.body.clientHeight > document.documentElement.clientHeight
    //       ? document.body.clientHeight
    //       : document.documentElement.clientHeight
    // }
    // this.wHeight = `height:${clientHeight}px`
    // console.log(this.wHeight);
    this.uuid = this.$route.query.key
    this.getData()
    if(!localStorage.getItem('id')){
      localStorage.setItem('id',uuidv4())
    }
  },
  methods: {
    dismissDialog () {
      this.show = false
    },
    /**
     * 助力
     */
    async showDialog (index) {
      let query = {
        uuid: this.uuid,
        id: localStorage.getItem('id'),
        index
      }
      let res = await postLike(query)
      if (res.code === 200 && res.data.flag) {
        this.count = res.data.count
        this.collect = res.data.collect? res.data.collect : 0
        this.expire = res.data.expire
        this.h = Math.ceil(this.expire/3600)
        this.activeArr = JSON.parse(JSON.stringify(res.data.index))
      }
      this.show = true
    },
    /**
     * 下载试试
     */
    confirm () {
      this.dismissDialog()
      this.$router.push({path: '/'})
    },
    /**
     * 根据uuid 查看点赞信息
     */
    async getData () {
      let query = {
        uuid: this.uuid
      }
      let res = await postLikeInfo(query)
      if (res.code === 200) {
        this.count = res.data.count
        this.collect = res.data.collect? res.data.collect : 0
        this.expire = res.data.expire
        this.h = Math.ceil(this.expire/3600)
        this.activeArr = JSON.parse(JSON.stringify(res.data.index))
      }
    }
  }
}
</script>

<style scoped>
.contain {
  width: 100%;
  background: url('../../../assets/image/bg_giveLike.png') no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}
.banner {
  position: relative;
  width: 100%;
  text-align: center;
}
.prompt {
  position: absolute;
  top: 1.5625rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 1.5rem;
  box-sizing: border-box;
}
.prompt div {
  border: 8px solid #fd919e;
  border-radius: 0.65rem;
  width: 100%;
  text-align: center;
  line-height: 1.5625rem;
  background: #fff;
  color: #fd919e;
  font-weight: 700px;
  font-size: 0.9rem;
  position: relative;
}

.prompt_icon {
  position: absolute;
  width: 2rem;
  height: 1rem;
  top: 50%;
  right: 1.9rem;
  transform: translateY(-50%);
}

.banner_icon {
  margin: 4rem 0 2rem;
  width: 8rem;
  height: 8rem;
}

.banner .coe {
  position: absolute;
  bottom: 2rem;
  right: 6rem;
  font-size: 1rem;
  font-weight: 700;
}

.content {
  font-size: 1rem;
  font-weight: 700;
  padding: 0 0.75rem;
}

.content_two {
  margin-top: 1.25rem;
}

.main {
  margin-top: 2rem;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.itemIcon {
  width: 4rem;
  height: 4rem;
}
.share_item {
  width: 30%;
  text-align: center;
  margin-bottom: 2rem;
}

.itemIcon_active {
  transform: translateY(-0.5rem);
}

.btn {
  /* background: rgba(255, 255, 255, 0.7); */
  background: white;
  margin: 0 auto;
  margin-bottom: 2rem;
  text-align: center;
  width: 50%;
  border-radius: 3.125rem;
  line-height: 2.4rem;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.money {
  margin-right: 1rem;
}

.dialog {
  background: #fbe54d;
  width: 22rem;
  /* height: 11.375rem; */
  color: black;
  border-radius: 2rem;
  position: relative;
  padding: 2.5rem 0 1rem;
  box-sizing: border-box;
}

.dialog_close {
  position: absolute;
  right: 1.25rem;
  width: 1.5625rem;
  height: 1.5625rem;
  top: 1.25rem;
}

.dialog_content {
  font-weight: 700;
  font-size: 1.25rem;
  /*font: 1.25rem / 2rem '';*/
  padding: 0 1.5rem;
}
.dialog_btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.25rem;
  height: 2.2rem;
  margin: 1rem 0 0;
}
.dialog_btns div {
  background: #fff;
  padding: 0 2em;
  font: 1rem/ 2rem '';
  border-radius: 1rem;
}
.dialog_btns .refused {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.5);
}

.ic_right_1 {
  position: absolute;
  width: 2.8125rem;
  height: 2.1875rem;
  right: 5.3125rem;
  top: 3.4rem;
}

.ic_right_2 {
  position: absolute;
  width: 2.8125rem;
  height: 2.1875rem;
  right: 4rem;
  top: 27.4rem;
}

.ic_right_3 {
  position: absolute;
  width: 2.8125rem;
  height: 2.1875rem;
  left: 0;
  top: 38rem;
  transform: rotate(30deg);
}

.ic_right_4 {
  position: absolute;
  width: 2.8125rem;
  height: 2.1875rem;
  right: 4rem;
  bottom: 0;
}

.ic_left_1 {
  position: absolute;
  width: 2.8125rem;
  height: 3.4375rem;
  right: 0;
  top: 19rem;
}
@media only screen and (device-width: 375px) and (device-height: 667px) {

  .prompt {
    position: relative;
    top: 1rem;
  }

  .banner_icon {
    margin: 1rem 0;
    width: 6rem;
    height: 6rem;
  }
  .banner .coe {
    position: absolute;
    bottom: 1rem;
    right: 7rem;
  }

  .main {
    margin-top: 0rem;
  }

  .share_item {
    text-align: center;
    margin-bottom: 1rem;
  }
  .ic_right_1 {
    transform: scale(0.8);
    position: absolute;
    right: 5.3125rem;
    top: 3rem;
  }
  .ic_right_2 {
    transform: scale(0.8);
    position: absolute;
    right: 4rem;
    top: 20.4rem;
  }
  .ic_right_3 {
    transform: scale(0.8);
    position: absolute;
    left: 0;
    top: 30rem;
  }
  .ic_right_4 {
    transform: scale(0.8);
    position: absolute;
    right: 4rem;
    bottom: 0;
  }
  .ic_left_1 {
    transform: scale(0.8);
    position: absolute;
    right: 0;
    top: 14rem;
  }
}
</style>
